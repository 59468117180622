body {
  margin: 0;
}

/*header styles*/

.header {
  background-image: linear-gradient(180deg, #0574b5, #0570af 90%);
  box-shadow: rgba(42, 73, 89, 0.09) 0 0 5px 0, rgba(13, 46, 65, 0.70) 0 1px 0 0;
}

.header.admin {
  background-image: linear-gradient(180deg, #933535, #9A4343 90%);
}

.header button {
  color: #d6fcff;
}

.homeLink {
  margin-left: 20px;
  display: inline-block;
}

.profileLinks {
    margin-right: 20px;
}

input:invalid {
  border-bottom: 1px solid #e53935!important;
}
textarea:invalid, select:invalid {
  border-color: #e53935!important;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.adminButton {
  margin : 10px;
  width: 90%;
}

@media only screen and (max-width: 599px) {
  .top-bar {
    background-color: #046095;
  }
}

.contest-title {
  margin-top: 10px;
  font-size:1.7rem;
  line-height: 30px;
}

.contest-subtitle {
  font-size: 1.0rem;
  line-height: 30px;
}

.tab.active {
    border-bottom: solid rgba(255,255,255,0.7) 3px;
    transition: all .28s ease;
}

.tab:hover {
    border-bottom: solid white 3px;
    transition: all .28s ease;
}

/* Entry form Styles */
.questionNumber {
  vertical-align:top;
  padding-right:10px;
}
.text {
  font-weight: bold;
}
input[type=number] {
  min-width: 50px;
  margin: 0 5px;
}
label {
  color:black;
}

.alwaysAtBottom {
  position: fixed;
  bottom: 5px;
  right: 5px;
  max-width:600px;
}

.moneyRemaining {
  padding:5px 5px;
  margin-right: 5px;
  border: darkgrey solid 1px;
  border-radius: 5px;
  font-weight: bold;
}

.invalidBackground {
  background-color: lightcoral;
}

.validBackground {
  background-color: lightgreen;
}

.help-btn {
  height: auto;
  line-height: auto;
  padding: 0;
}

.entry-form-container {
  margin-bottom: 70px;
}

/* Leaderboard Styles */
.wrongAnswer {
	color: darkred;
	font-weight: bold;
}
.rightAnswer {
	color: darkgreen;
	font-weight: bold;
}

.leaderboardContainer tr[currentuser] {
	background-color: lightyellow !important;
}

.leaderboardContainer tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

@media only screen and (max-width: 599px) {
  .leaderboardContainer td {
    width: 50%;
    height: 50px;
    white-space: normal;
  }
}

.leaderboardContainer .userIcon {
  margin-right: 2px;
}
